import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RUNLY_DARK_MINT } from "@runly/gatsby-theme";
import tc from "tinycolor2";

const getTitleBackground = angle =>
	`linear-gradient(${angle}deg, ${tc(RUNLY_DARK_MINT)
		.darken(3)
		.spin(15)
		.toString()}, ${RUNLY_DARK_MINT}, ${tc(RUNLY_DARK_MINT)
		.lighten(3)
		.spin(-8)
		.toString()})`;

const CtaTitle = ({ children, angle = 90 }) => {
	const classes = useTitleStyles();

	return (
		<>
			<style>{`
		@supports (background-clip: text) or (-webkit-background-clip: text) {
			.${classes.root}-${angle} {
				background: ${getTitleBackground(angle)};
				color: transparent;
				background-clip: text;
				-webkit-background-clip: text;
			}
		}
`}</style>
			<Typography
				variant="h4"
				component="h2"
				className={`${classes.root}-${angle}`}
				gutterBottom
			>
				{children}
			</Typography>
		</>
	);
};

const useTitleStyles = makeStyles({
	root: {
		color: RUNLY_DARK_MINT
	}
});

export default CtaTitle;
