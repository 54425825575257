import React from "react";

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { SKEW } from "../constants";

const CtaSection = ({
	children,
	backgroundColor = "transparent",
	...props
}) => {
	const classes = useSectionStyles({
		backgroundColor
	});

	return (
		<Box component="section" className={classes.root} {...props}>
			<div className={classes.background} />
			{children}
		</Box>
	);
};

const useSectionStyles = makeStyles(theme => {
	const mdUp = theme.breakpoints.up("md");

	return {
		root: {
			position: "relative"
		},

		background: {
			position: "absolute",
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			zIndex: -1,
			backgroundColor: ({ backgroundColor }) =>
				theme.palette.background[backgroundColor] || "transparent",
			[mdUp]: {
				transformOrigin: "50% 50%",
				transform: `skewY(${SKEW})`
			}
		}
	};
});

export default CtaSection;
