import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
	BRIGHT_BLUE_GRADIENT_COLORS,
	BRIGHT_BLUE_GRADIENT
} from "../hero/cards";

import { SKEW } from "../constants";

const BigBlue = ({ children }) => {
	const classes = useBigBlueStyles();
	return (
		<section className={classes.container}>
			<div className={classes.bg} />
			{children}
		</section>
	);
};

const useBigBlueStyles = makeStyles(theme => {
	const mdUp = theme.breakpoints.up("md");
	return {
		container: {
			position: "relative",
			color: theme.palette.getContrastText(BRIGHT_BLUE_GRADIENT_COLORS[0])
		},
		bg: {
			zIndex: -2,
			position: "absolute",
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			background: BRIGHT_BLUE_GRADIENT,
			[mdUp]: {
				transform: `skewY(${SKEW})`
			}
		}
	};
});

export default BigBlue;
