import React, { cloneElement } from "react";

import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import CtaText from "./cta/text";

const FeatureRow = ({
	title,
	children,
	art,
	reverse,
	isLast,
	sm = 7,
	md = 8
}) => {
	const classes = useStyles();

	return (
		<Box mb={!isLast ? 24 : undefined}>
			<Grid
				container
				spacing={4}
				alignItems="center"
				direction={reverse ? "row-reverse" : "row"}
			>
				<Grid item xs={12} sm={sm} md={md}>
					<CtaText title={title}>{children}</CtaText>
				</Grid>
				<Grid item xs={12} sm={12 - sm} md={12 - md}>
					{cloneElement(art, { className: classes.art })}
				</Grid>
			</Grid>
		</Box>
	);
};

const useStyles = makeStyles({
	art: {
		margin: "0 auto",
		maxWidth: "100%"
	}
});

export default FeatureRow;
