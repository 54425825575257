import React from "react";

import { Box, Container } from "@material-ui/core";

import { Link } from "@runly/gatsby-theme";

import Feature from "../../feature-row";
import CtaSection from "../../cta";
import SectionHead from "../section-head";

import { ReactComponent as MeditatingArt } from "./meditating.svg";
import { ReactComponent as StaticAssetsArt } from "./static-assets.svg";
import { ReactComponent as WebDeveloperArt } from "./web-developer.svg";

const MeetYou = props => (
	<CtaSection backgroundColor="paper">
		<Box pt={12} {...props}>
			<Container maxWidth="lg">
				<SectionHead title="We'll meet you where you are" mb={18}>
					Whether you have a build step for your JS or you just include script
					tags on server-rendered pages, Runly UI works for you.
				</SectionHead>
			</Container>

			<Container maxWidth="md">
				<Feature title="Ease of Use" art={<MeditatingArt />} reverse>
					Include a <code>script</code> tag on your page linking to the Runly UI
					CDN. Then drop a <code>div</code> on your page where you would like to
					render runly components. Or import the Runly UI npm package and bundle
					it with the rest of your app.
				</Feature>

				<Feature
					title="Use your favorite CSS framework"
					art={<StaticAssetsArt />}
				>
					Whether you are a fan of{" "}
					<Link
						color="inherit"
						underline="always"
						href="https://getbootstrap.com/"
					>
						Bootstrap
					</Link>
					,{" "}
					<Link color="inherit" underline="always" href="https://material.io/">
						Material Design
					</Link>
					,{" "}
					<Link color="inherit" underline="always" href="https://bulma.io/">
						Bulma
					</Link>
					, or{" "}
					<Link
						color="inherit"
						underline="always"
						href="https://get.foundation/"
					>
						Foundation
					</Link>
					, Runly UI has components in your particular flavor.
				</Feature>

				<Feature
					title="Maximum Customization"
					art={<WebDeveloperArt />}
					reverse
					isLast
				>
					If you prefer to get your hands dirty, you can use our JS API directly
					and implement your own UX without having to worry about the
					particulars of connecting to the Runly API or dealing with WebSockets.
				</Feature>
			</Container>
		</Box>
	</CtaSection>
);

export default MeetYou;
