import React from "react";

import { Box, Grid, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { CtaBody } from "../../cta/text";

import { ReactComponent as UploadingArt } from "./uploading.svg";
import { ReactComponent as AddColorArt } from "./add-color.svg";

import BigBlue from "../big-blue";
import SectionHead from "../section-head";

const Smarter = props => {
	const classes = useStyles();

	return (
		<BigBlue>
			<Box clone {...props}>
				<Container maxWidth="lg">
					<Grid container spacing={4} justify="space-evenly">
						<Grid item xs={12}>
							<SectionHead
								title="Long running jobs, made smarter"
								textAlign="center"
								mb={8}
							>
								Seamlessly connect your app with your background jobs using
								Runly UI.
							</SectionHead>
						</Grid>

						<Grid item xs={12} md={4}>
							<UploadingArt className={classes.gridGraphic} />

							<Typography variant="h4" component="h2" gutterBottom>
								Report job progress
							</Typography>
							<CtaBody color="inherit">
								Don't make users guess at whether their operation worked. Easily
								integrate a real-time progress bar of your job's progress into
								your app. Optionally allow your users to retry failed jobs
								directly from your app.
							</CtaBody>
						</Grid>
						<Grid item xs={12} md={4}>
							<AddColorArt className={classes.gridGraphic} />

							<Typography variant="h4" component="h2" gutterBottom>
								Match your brand
							</Typography>
							<CtaBody color="inherit">
								Easily override component styles to match the look and feel of
								your app.
							</CtaBody>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</BigBlue>
	);
};

const useStyles = makeStyles(theme => {
	return {
		gridGraphic: {
			display: "block",
			height: theme.spacing(30),
			margin: `${theme.spacing(4)}px auto`,
			width: "100%"
		}
	};
});

export default Smarter;
