import React from "react";

import { Box, Typography, Grid, Container } from "@material-ui/core";

import { Link } from "@runly/gatsby-theme";

import CtaButton from "../cta/button";

const GetStartedSmall = ({ text = "Get started for free", ...props }) => (
	<Box textAlign="center" {...props}>
		<Typography gutterBottom>
			<CtaButton href="/dashboard/">{text}</CtaButton>
		</Typography>

		<Typography>
			or <Link to="/docs/">read the docs</Link>.
		</Typography>
	</Box>
);

const GetStartedLarge = ({ maxWidth = "lg" }) => (
	<Container maxWidth={maxWidth}>
		<Grid container justify="center" alignItems="flex-start" spacing={4}>
			<Grid item xs={12} sm={4}>
				<Box textAlign={["center", "left"]}>
					<Typography variant="h4">
						Get started with the Runly Platform for free
					</Typography>
					<Typography variant="subtitle1">
						No credit card, no commitment
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12} sm={4}>
				<GetStartedSmall text="Try it now" />
			</Grid>
		</Grid>
	</Container>
);

export default GetStartedLarge;
