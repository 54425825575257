import React from "react";
import { Typography, Box } from "@material-ui/core";

import { useCtaTextStyles } from "../cta/text";

const SectionHead = ({ title, children, ...boxProps }) => {
	const classes = useCtaTextStyles();

	const body = (
		<>
			<Typography variant="h3" component="h2" align="center">
				{title}
			</Typography>
			<Typography
				variant="subtitle1"
				component="h3"
				className={classes.body}
				align="center"
			>
				{children}
			</Typography>
		</>
	);

	if (!Object.keys(boxProps).length) {
		return body;
	}

	return <Box {...boxProps}>{body}</Box>;
};

export default SectionHead;
