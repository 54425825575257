import React from "react";
import { graphql } from "gatsby";

import { Box, Grid, Container } from "@material-ui/core";
import { Link } from "@runly/gatsby-theme";

import Layout from "../layout";
import Seo from "../seo";

import Headline from "../hero/headline";

import Smarter from "../landing/smarter";
import MeetYou from "../landing/meet-you";
import { ReactComponent as ReactArt } from "../landing/react.svg";

import CtaSection from "../cta";
import GetStarted from "../landing/get-started";

const UiPage = ({ location, data: { logo } }) => (
	<Layout location={location} maxWidth="lg" disableFooterMargin>
		<Seo
			title="Generate an amazing UX from your app's background jobs"
			description="Easily integrate fillable forms, progress bars, and more in your app for your users to run and view background jobs."
			image={logo.cover.img.src}
		/>

		<Box clone pb={18}>
			<Container maxWidth="lg">
				<Grid container spacing={4} alignItems="center">
					<Grid item xs={12} md={7}>
						<Headline title="User experiences powered by Runly">
							It's more than{" "}
							<Link to="/" color="inherit" underline="always">
								background jobs
							</Link>{" "}
							&mdash; it's a platform powerful enough to create amazing user
							experiences at scale.
						</Headline>
					</Grid>
					<Grid item xs={12} md={5}>
						<ReactArt />
					</Grid>
				</Grid>
			</Container>
		</Box>

		<Smarter py={20} />
		<MeetYou py={20} />

		<CtaSection py={20}>
			<GetStarted mt={8} />
		</CtaSection>
	</Layout>
);

export default UiPage;

export const uiQuery = graphql`
	query UiQuery {
		logo: file(sourceInstanceName: { eq: "logos" }, name: { eq: "coverjs" }) {
			cover: childImageSharp {
				img: fixed(width: 800) {
					src
				}
			}
		}
	}
`;
