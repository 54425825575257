import React from "react";

import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import CtaTitle from "./title";

const CtaText = ({ title, titleAngle, children, ...boxProps }) => (
	<Box {...boxProps}>
		<CtaTitle angle={titleAngle}>{title}</CtaTitle>
		{children ? <CtaBody>{children}</CtaBody> : null}
	</Box>
);

export const CtaBody = ({ children, ...props }) => {
	const classes = useCtaTextStyles();

	return (
		<Typography
			className={classes.body}
			color="textSecondary"
			component="div"
			{...props}
		>
			{children}
		</Typography>
	);
};

export const useCtaTextStyles = makeStyles(theme => ({
	body: {
		fontFamily: theme.typography.h1.fontFamily,
		fontSize: theme.typography.h5.fontSize
	}
}));

export default CtaText;
