import React from "react";

import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const Hero = ({ title, children, ...props }) => (
	<div {...props}>
		<Typography variant="h2" component="h1" gutterBottom>
			{title}
		</Typography>

		<Typography variant="h4" component="h2" classes={useHeadingStyles()}>
			{children}
		</Typography>
	</div>
);

const useHeadingStyles = makeStyles(theme => ({
	h4: {
		fontWeight: theme.typography.fontWeightRegular
	}
}));

export default Hero;
