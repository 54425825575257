import React from "react";
import { Box, Card, CardHeader, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const BRIGHT_BLUE_GRADIENT_COLORS = ["#4a36ff", "#3fa9f5", "#2fd6de"];
const [L, M, R] = BRIGHT_BLUE_GRADIENT_COLORS;
export const BRIGHT_BLUE_GRADIENT = `linear-gradient(80deg, ${L}, ${M} 75%, ${R})`;

export const ColorHeader = props => {
	const classes = useColorHeaderStyles();

	return (
		<CardHeader
			titleTypographyProps={{ color: "primary", classes }}
			{...props}
		/>
	);
};

const useColorHeaderStyles = makeStyles(theme => {
	return {
		root: {
			"& svg": {
				display: "block",
				width: "33%",
				margin: "0 auto"
			},
			[theme.breakpoints.up("sm")]: {
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				"& svg": {
					width: "2em",
					height: "2em",
					margin: 0,
					marginRight: theme.spacing(1)
				}
			}
		}
	};
});

export const RoundedCard = ({ children, ...props }) => {
	const classes = useCardStyles();
	return (
		<Box clone py={[4, 2, 0]} px={[4, 2, 0]}>
			<Card className={classes.card} elevation={8} {...props}>
				<Box px={[0, 1, 1, 2, 4]} py={[0, 0, 1, 2, 3]}>
					{children}
				</Box>
			</Card>
		</Box>
	);
};

const useCardStyles = makeStyles(theme => {
	return {
		card: {
			flexGrow: 1,
			borderRadius: theme.spacing(3),
			"& ~ $card": { marginTop: theme.spacing(4) }
		}
	};
});

export const Content = ({ children }) => {
	const classes = useContentStyles();
	return (
		<CardContent className={classes.content}>
			<Box pb={4}>{children}</Box>
		</CardContent>
	);
};

const useContentStyles = makeStyles(theme => {
	return {
		content: {
			paddingTop: 0,
			fontSize: theme.typography.pxToRem(18),
			fontWeight: 600,
			color: theme.palette.text.secondary
		}
	};
});
