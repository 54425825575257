import React from "react";
import { Button, RootRef } from "@material-ui/core";
import {
	Link as GLink,
	RUNLY_MINT,
	RUNLY_DARK_MINT
} from "@runly/gatsby-theme";
import { makeStyles } from "@material-ui/core/styles";

const _Link = (props, ref) => (
	<RootRef rootRef={ref}>
		<GLink {...props} />
	</RootRef>
);

const Link = React.forwardRef(_Link);

const CtaButton = ({ children, href, ...props }) => {
	const classes = useStyles();
	return (
		<Button
			size="large"
			color="primary"
			variant="contained"
			component={Link}
			to={href}
			{...props}
			classes={classes}
		>
			{children}
		</Button>
	);
};

const useStyles = makeStyles(theme => {
	return {
		root: {
			boxShadow: "none",
			...theme.typography.h4,
			fontWeight: theme.typography.subtitle2.fontWeight,
			textTransform: "none"
		},
		containedPrimary: {
			borderRadius: theme.spacing(1),
			backgroundColor: RUNLY_MINT,
			"&:hover, &:focus": {
				backgroundColor: RUNLY_DARK_MINT,
				boxShadow: "none"
			}
		}
	};
});

export default CtaButton;
